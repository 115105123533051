import React, { useEffect, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import "./AdminLayout.scss";
import { useNavigate } from "react-router-dom";
import { FaCogs, FaFileAlt, FaUsers, FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import BarIcon from "../components/BarIcon/BarIcon";
import Logo from "../components/Logo/Logo";
import "./AdminLayout.scss";

const AdminLayout = ({ children }) => {

  const navigate = useNavigate()

  const authToken = localStorage.getItem("authToken");
  console.log(authToken, "lll")
  let user = null;
  try {
    user = authToken ? JSON.parse(localStorage.getItem("userData")) : null;
  } catch (error) {
    console.error("Invalid user data in localStorage:", error);
  }



  // Redirect to /office if isAdmin is not true
  useEffect(() => {
    if (!user || user?.isAdmin !== true) {
      navigate("/");
    }
    if (!authToken) {
      navigate("/login");
    }
  }, [user, navigate]);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  // Toggle Dropdown
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  // Toggle Sidebar
  const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

  // Handle Logout
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  // Get User Data from Local Storage


  return (
    <div className="admin-layout">
      {/* Header Section */ }
      <header className="site-header">
        <div className="container">
          <Row className="admin-bashboard-header align-items-center">
            <Col sm={ 4 } md={ 4 } lg={ 4 } >
              <div className="bar-icn">
                <BarIcon onClick={ toggleSidebar } />
              </div>
            </Col>
            <Col className="logo" sm={ 4 } md={ 4 } lg={ 4 } >
              <Logo />
            </Col>
            { user?.isAdmin && (
              <Col sm={ 4 } md={ 4 } lg={ 4 } className="text-end">
                <Dropdown show={ isDropdownOpen } onToggle={ toggleDropdown }>
                  <Dropdown.Toggle
                    className="profile-toggle custom-btn"
                    onClick={ toggleDropdown }
                  >
                    <FaUserCircle className="profile-icon" />
                    <span className="user-name ">{ user?.fullName || "Admin" }</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="admin-log-out">
                    <Dropdown.Item onClick={ handleLogout }>
                      <FaSignOutAlt className="logout-icon" />
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            ) }
          </Row>
          {/* Mobile Navigation */ }
          <div className="admin-mobile-header-wrapper">
            <div className=" align-items-center justify-content-between d-flex">
              <Col className="logo" sm={ 4 } md={ 4 } lg={ 4 } >
                <Logo />
              </Col>
              <Col className="logo" sm={ 4 } md={ 4 } lg={ 4 } >

                <Dropdown className="mobile-view-bar">
                  <Dropdown.Toggle id="dropdown-basic" className="user-icon-mobile-view">
                    <FaUserCircle className="profile-icon" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={ () => navigate("/login") }>
                      <span className="user-name ">{ user?.fullName || "Admin" }</span>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    { authToken && (
                      <>
                        <Dropdown.Item onClick={ () => navigate("/admin-dashboard") }>
                          <span className="dashboard-icon">

                          </span>{ " " }
                          Tax-return
                        </Dropdown.Item>
                        <Dropdown.Item onClick={ () => navigate("/office/templates") }>
                          <span className="dashboard-icon">

                          </span>{ " " }
                          Templates
                        </Dropdown.Item>
                        <Dropdown.Item onClick={ () => navigate("/office/users") }>
                          <span className="dashboard-icon">

                          </span>{ " " }
                          Users
                        </Dropdown.Item>
                        <Dropdown.Item onClick={ handleLogout }>
                          <FaSignOutAlt className="logout-icon" />
                          Logout
                        </Dropdown.Item>
                      </>
                    ) }
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </div>
          </div>

        </div>
      </header>

      {/* Sidebar and Content Section */ }
      <div className="admin-container">
        <div className={ `sidebar-wrapper ${isSidebarOpen ? "open" : ""}` }>

          <aside className="admin-sider ">

            <ul className="admin-menu">
              <li
                className="menu-item"
                onClick={ () => {
                  navigate("/admin-dashboard");

                  setSidebarOpen(true)
                } }
              >
                <FaFileAlt className="menu-icon" />
                <span className="menu-name">  Tax-return</span>

              </li>
              <li
                className="menu-item"
                onClick={ () => {
                  navigate("/office/templates");

                  setSidebarOpen(true)
                } }
              >
                <FaCogs className="menu-icon" />
                <span className="menu-name">  Templates</span>

              </li>
              <li
                className="menu-item"
                onClick={ () => {
                  navigate("/office/users");

                  setSidebarOpen(true)
                } }
              >
                <FaUsers className="menu-icon" />
                <span className="menu-name">     Users</span>

              </li>
            </ul>
          </aside>

          <main className="admin-content"> { user?.isAdmin == true && children }</main>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
