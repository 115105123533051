import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Nav.scss";
function Nav({ handleClose }) {
  const location = useLocation();
  const navigate = useNavigate();
  const token=localStorage.getItem("authToken")


 

  const isActive = (path) => {
    if (
      path === "/" &&
      (location.pathname === "/" || location.pathname === "")
    ) {
      return "nav-items active";
    }
    document.body.scrollIntoView({ behavior: "smooth", block: "start" });
    return location.pathname === path ? "nav-items active" : "nav-items";
  };
  return (
    <div className="navigation">
      <ul className="nav-list d-flex">
        <li
          onClick={() => {
            navigate("/");
            if (handleClose) {
              handleClose();
            }
          }}
          className={isActive("/")}
        >
          Home
        </li>
        <li
          onClick={() => {
            navigate("/about");
            if (handleClose) {
              handleClose();
            }
          }}
          className={isActive("/about")}
        >
          About
        </li>
        <li
          onClick={() => {
            navigate("/contact-us");
            if (handleClose) {
              handleClose();
            }
          }}
          className={isActive("/contact-us")}
        >
          Contact Us
        </li>

        <li
          className={isActive("/login")}
          onClick={() => {
            if (handleClose) {
              handleClose();
            }
          }}
        >
           <Link to={!token? "/login":"/dashboard"}>
          Client Engagement/Questionnaire
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Nav;
