import React, { useState } from "react";
import Logo from "../../components/Logo/Logo";
import "./Header.scss";
import HeaderIcon from "../../components/HeaderIcons/HeaderIcon";
import Navigation from "../../components/Navigation/Nav";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Offcanvas from 'react-bootstrap/Offcanvas';
import BarIcon from "../../components/BarIcon/BarIcon";
const Header = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <header className="site-header">
      <div className="container">
        <Row className="header-wrapper" >
          <Col className="logo" xs={ 4 } md={ 2 }>
            <Logo />
          </Col>
          <Col className="navigation-wrapper" xs={4} md={ 7 }>

            <BarIcon className="Bar-icon" onClick={ handleShow } />
            <Navigation />
            <Offcanvas show={ show } onHide={ handleClose } className="mobile-view-nav">
              <Offcanvas.Header closeButton>
                <div className="nav-logo">
                  <Logo></Logo>
                </div>
                <Offcanvas.Title> <h6>ZUL TAX SERVICES INC</h6></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="mobile-sider">
                <Navigation  handleClose={handleClose}/>
              </Offcanvas.Body>
            </Offcanvas>

          </Col>
          <Col className="header-icon" xs={ 4 } md={ 3 }>
            <HeaderIcon />
          </Col>
        </Row>
      </div>
    </header>
  );
};

export default Header;
