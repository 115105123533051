import React from "react";
import PhoneIcon from "./../../assets/images/call-calling.svg";
import EmailIcon from "./../../assets/images/sms-search.svg";
import "./Footer.scss";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Logo from "../../components/Logo/Logo";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = path => {
    navigate(path);
    document.body.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <footer className="site-footer">
      <div className="container">
        <Row className="main-footer-wrapper d-flex">
          <Col className="logo-wrapper " sm={12} md={4}>
          <Link to='/'>
            <div className="footer-logo">
              <Logo />
            </div>
            </Link>
            <div className="footer-text">
              <address>
                16742 Gothard Street Ste 221, <br /> Huntington Beach,
                <br />
                California 92647, United States
              </address>
            </div>
          </Col>
          <Col className="footer-links " sm={ 12 } md={ 4 }>
            <ul className="footer-list">
              <li className="footer-items">
                <span onClick={ () => handleNavigation("/") }>Home</span>
              </li>
              <li className="footer-items">
                <span onClick={ () => handleNavigation("/about") }>About</span>
              </li>
              <li className="footer-items">
                <span onClick={ () => handleNavigation("/contact-us") }>
                  Contact Us
                </span>
              </li>
            </ul>
          </Col>
          <Col className="footer-social " sm={ 12 } md={ 4 }>
            <div className="main-social-media-wrapper">
              
              <div className="phone-number">
                <span className="phone-icon">
                  <a href="tel:+17148459758">
                    <img src={ PhoneIcon } alt="PhoneIcon" loading="lazy" />
                    (714) 845-9758
                  </a>
                </span>
              </div>
              <div className="Email">
                <span className="email-icon">
                  <a href="mailto:info@zultaxservices.com">
                    <img src={ EmailIcon } alt="emailIcon"  loading="lazy"/>
                    info@zultaxservices.com
                  </a>
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="coppy-right">
        <p>Copyright © { new Date().getFullYear() } ZUL Tax Services Inc - All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
