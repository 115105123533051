import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import AuthRoute from "./AuthRoute";
import LayoutComponent from "../Layout/LayoutComponent";
import AdminLayout from "../Layout/AdminLayout";



const Home = React.lazy(() => import('../pages/Home/Home'));
const  AdminDashboard =React.lazy(() => import('../pages/Admin/AdminDashboard/AdminDashboard'));
const DashboardPage = React.lazy(() => import('../pages/Dashboard/Dashboard'));
const CustomerLogin = React.lazy(() => import('../pages/Login/Login'));
const About = React.lazy(() => import('../pages/About/About'));
const Contact = React.lazy(() => import('../pages/Contact/Contact'));
const ForgetPassword = React.lazy(() => import('../pages/ForgetPassword/ForgetPassword'));
const NewPassword = React.lazy(() => import('../components/NewPassword/NewPassword'));
const DashboardFormDetails = React.lazy(() => import('../components/DashboardFormDetails/DashboardFormDetails'));
const DashboardForm = React.lazy(() => import('../components/DashboardForm/DashboardForm'));
const TemplatesPage = React.lazy(() => import('../pages/Admin/TemplatesPage/TemplatesPage'));
const Users = React.lazy(() => import('../pages/Admin/Users/Users'));
const PaypalSuccess = React.lazy(() => import('../pages/PaypalPages/PaypalSuccess'));
const PaypalCancle = React.lazy(() => import('../pages/PaypalPages/PaypalCancle'));
const ViewTaxDetails = React.lazy(() => import('../pages/Admin/ViewTaxDetails/ViewTaxDetails'));
const DocuSign = React.lazy(() => import('../pages/DocuSign/DocuSign'));
const PayemntCheckOut = React.lazy(() => import('../pages/PaymentCheckOut/PaymentCheckOut'));
const OtpScreen = React.lazy(() => import('../pages/OtpScreen/OtpScreen'));





const RoutesComponent = () => {
  const routesArr = [
   
    {
      path: "/admin-dashboard",
      component: <AdminDashboard />,
      wrapRoute: "adminProtectedRoute",
      layoutType: "admin",
    },
    {
      path: "/office/view-user-tax-details/:id",
      component: <ViewTaxDetails />,
      wrapRoute: "adminProtectedRoute",
      layoutType: "admin",
    },
    {
      path: "/office/users",
      component: <Users />,
      wrapRoute: "adminProtectedRoute",
      layoutType: "admin",
    },
    {
      path: "/office/templates",
      component: <TemplatesPage />,
      wrapRoute: "adminProtectedRoute",
      layoutType: "admin",
    },
    {
      path: "/",
      component: <Home />,
      wrapRoute: "publicRoute",
      layoutType: "default",
    },
    {
      path: "/login",
      component: <CustomerLogin />,
      wrapRoute: "authRoute",
      layoutType: "default",
    },
    {
      path: "/otp/:id",
      component: <OtpScreen />,
      wrapRoute: "publicRoute",
      layoutType: "default",
    },
    {
      path: "/about",
      component: <About />,
      wrapRoute: "publicRoute",
      layoutType: "default",
    },

    {
      path: "/contact-us",
      component: <Contact />,
      wrapRoute: "publicRoute",
      layoutType: "default",
    },
    {
      path: "/forget-password",
      component: <ForgetPassword />,
      wrapRoute: "authRoute",
      layoutType: "default",
    },
    {
      path: "/reset-password/:token",
      component: <NewPassword />,
      wrapRoute: "authRoute",
      layoutType: "default",
    },
    {
      path: "/dashboard",
      component: <DashboardPage />,
      wrapRoute: "protectedRoute",
      layoutType: "default",
    },
    {
      path: "/tax-form/:id",
      component: <DashboardForm />,
      wrapRoute: "protectedRoute",
      layoutType: "default",
    },
    {
      path: "/tax-form",
      component: <DashboardForm />,
      wrapRoute: "protectedRoute",
      layoutType: "default",
    },

    {
      path: "/tax-return-details/:id",
      component: <DashboardFormDetails />,
      wrapRoute: "protectedRoute",
      layoutType: "default",
    },
    {
      path: "/paypal/success",
      component: <PaypalSuccess />,
      wrapRoute: "protectedRoute",
      layoutType: "default",
    },
    {
      path: "/paypal/cancle",
      component: <PaypalCancle />,
      wrapRoute: "protectedRoute",
      layoutType: "default",
    },
    {
      path: "/signature-complete",
      component: <DocuSign />,
      wrapRoute: "protectedRoute",
      layoutType: "default",
    },
    {
      path: "/payment/checkout",
      component: <PayemntCheckOut />,
      wrapRoute: "publicRoute",
      layoutType: "default",
    },
  ];
  return (
    <Suspense fallback={<div></div>}>

    <Routes>
      { routesArr.map((element, key) => {
        const layoutType = element.layoutType || "default";
        switch (element.wrapRoute) {
          case "publicRoute":
            return (
              <Route
                key={ key }
                path={ element.path }
                element={
                  <LayoutComponent layoutType={ layoutType } element={ element }>
                    { element.component }
                  </LayoutComponent>
                }
              />
            );

          case "authRoute":
            return (
              <Route
                key={ key }
                path={ element.path }
                element={
                  <>
                    <LayoutComponent layoutType={ layoutType } element={ element }>
                      <AuthRoute element={ element.component } />
                    </LayoutComponent>
                  </>
                }
              />
            );

          case "protectedRoute":
            return (
              <Route
                key={ key }
                path={ element.path }
                element={
                  <>
                    <LayoutComponent layoutType={ layoutType } element={ element }>
                      <ProtectedRoute element={ element.component } />
                    </LayoutComponent>
                  </>
                }
              />
            );

          case "adminProtectedRoute":
            return (
              <Route
                key={ key }
                path={ element.path }
                element={
                  <AdminLayout>
                    { element.component }
                  </AdminLayout>
                }
              />
            );
          default:
            return (
              <Route
                key={ key }
                path={ element.path }
                element={ element.component }
              />
            );
        }
      }) }
      <Route path="*" element={ <Navigate to="/" /> } />
    </Routes>
    </Suspense>
  );
};

export default RoutesComponent;
