import React, { useEffect, useState } from "react";
import HeaderLogo from "./../../assets/images/Logo.svg";
import {Link} from 'react-router-dom'
const Logo = () => {
const [admin,setAdmin]=useState(true)
const data=JSON.parse(localStorage.getItem("userData"))
const [data2,setData]=useState(data?data:'')


  useEffect(()=>{
if (data2.isAdmin==true) {
  setAdmin(true)  
}else{
  setAdmin(false)
}
  },[data2])
  return (

    
      admin? <div className="header-log">
      <img src={ HeaderLogo } alt="logo" loading="lazy" />
    </div>: <Link to='/'>
    <div className="header-log">
      <img src={ HeaderLogo } alt="logo" loading="lazy" />
    </div>
    </Link>
  
    
  );
};

export default Logo;
