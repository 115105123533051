import React from "react";
import UserIcon from "../../assets/Icons/UserIcon";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import LogOutIcon from "../LogoutIcon/LogoutIcon";
import DashboardIcon from "../DashboardIcon/DashboardIcon";
import { useLoading } from "../../context/LoadingContext";

function HeaderIcon() {
  const navigate = useNavigate();
 

  // Retrieve user data and auth token
  const authToken = localStorage.getItem("authToken");
  let user = null;
  try {
    user = authToken ? JSON.parse(localStorage.getItem("userData")) : null;
  } catch (error) {
    console.error("Invalid user data in localStorage:", error);
  }

  const logout = () => {
    localStorage.clear();
    navigate("/");
    
  };

  return (
    <div className="header-bar-icons d-flex">
      { authToken ? (
        // Desktop View
        <Dropdown className="dashboard-view-bar">
          <Dropdown.Toggle className="user-icon">
            <UserIcon />
            <span className="user-name ">
              { user?.fullName || "SIGN IN" }
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <UserIcon />
              <span className="user-name ">

                { user?.fullName?.toUpperCase() || "SIGN IN" }
              </span>
            </Dropdown.Item>
            <Dropdown.Divider />
            { authToken && (
            <><Dropdown.Item onClick={() => navigate("/dashboard")}>
                <span className="dashboard-icon">
                  <DashboardIcon />
                </span>
                Dashboard
              </Dropdown.Item><Dropdown.Item onClick={logout}>
                  <span className="logout">
                    <LogOutIcon />
                  </span>{" "}
                  Logout
                </Dropdown.Item></>
            )}
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        // Unauthenticated State
        <>
          <div onClick={ () => navigate("/login") } className="user-icon">
            <Link to="login">
              <UserIcon />
              <span className="user-name">
                SIGN IN
              </span>
            </Link>
          </div>

        </>
      ) }

      {/* Mobile Navigation */ }
      <Dropdown className="mobile-view-bar">
        <Dropdown.Toggle id="dropdown-basic" className="user-icon-mobile-view">
          <FaUser size={ 20 } />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item  onClick={ () => navigate("/login") }>
            <UserIcon />
            <span className="user-name ">

              { user?.fullName?.toUpperCase() || "SIGN IN" }
            </span>
          </Dropdown.Item>
          <Dropdown.Divider />
          { authToken && (
            <>
              <Dropdown.Item onClick={ () => navigate("/dashboard") }>
                <span className="dashboard-icon">
                  <DashboardIcon />
                </span>{ " " }
                Dashboard
              </Dropdown.Item>
              <Dropdown.Item onClick={ logout }>
                <span className="logout">
                  <LogOutIcon />
                </span>{ " " }
                Logout
              </Dropdown.Item>
            </>
          ) }
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default HeaderIcon;
